@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.5;
  background: url(https://images.unsplash.com/photo-1489599849927-2ee91cede3ba)
    no-repeat center center fixed;
  background-size: cover;
  background-color: #000000;
  height: 100%;
}

h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 200%;
  font-weight: 700;
  color: #f0f0f0;
  margin: 0;
}

h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 140%;
  font-weight: 700;
  color: #ffffff;
  margin: 0 auto;
  text-align: left;
  text-shadow: 0px 0px 6px #000000;
}

h3 {
  font-family: 'Roboto', sans-serif;
  font-size: 90%;
  font-weight: 400;
  color: #ffffff;
  margin: 0 auto;
  text-align: left;
  text-shadow: 0px 0px 6px #000000;
}

p {
  font-family: 'Roboto', sans-serif;
  font-size: 115%;
  font-weight: 400;
  color: #e3e3e3;
  margin: 5px auto 15px;
}

a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.15s ease-in-out;
  text-shadow: 0px 0px 6px #000000;
}

a:hover {
  color: #3fbfb2;
}

.error {
  width: 100%;
  margin: 10px auto;
  padding: 10px;
  font-size: 14px;
  background-color: lightpink;
  border-radius: 3px;
  color: darkred;
}

main {
  width: 90%;
  max-width: 600px;
  margin: 5% auto 0;
  text-align: center;
}

.header {
  margin: 0;
}

.logo {
  width: 15%;
  cursor: pointer;
}

.logo:hover {
  opacity: 0.8;
}

section {
  margin: 20px auto;
}

.search-form input {
  display: block;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: 0;
  border: 1px solid transparent;
  background-color: #e4e4e4;
  width: 100%;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 0 auto 10px auto;
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
}

.search-form input:hover {
  background-color: #e4e4e4;
}

.search-form input:focus {
  background-color: #e4e4e4;
  color: #000000;
}

.search-form button {
  background-color: #48dacb;
  padding: 10px 15px;
  border: 1px solid transparent;
  color: #333333;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  transition: 0.15s ease-in-out;
  margin-bottom: 10px;
}

.search-form button:hover {
  background-color: #3fbfb2;
  color: #000;
}

.data {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 350px;
  background-color: #999999 !important;
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0px 0px 6px #000000;
  background: no-repeat center center;
  background-size: cover;
}

.data-header {
  position: absolute;
  width: 100%;
  padding: 10px;
  top: 0;
  left: 0;
}

.data-lower {
  position: absolute;
  width: 100%;
  padding: 0 10px 10px 10px;
  bottom: 0;
  left: 0;
}

.data-locations {
  width: 100%;
  margin: 0 auto;
}

.location-btn {
  font-size: 80%;
  color: #1a1a1a;
  padding: 5px 10px;
  margin: 5px 5px 0px 0px;
  float: left;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.8);
  text-shadow: none;
  box-shadow: 0px 0px 4px #333;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

.location-btn:hover {
  color: #000000;
  background-color: rgba(255, 255, 255, 1);
}

footer {
  margin: 20px auto;
  padding: 1% 0;
}

.footer p {
  font-size: 80%;
  margin: 2px auto;
  color: #7b7b7b;
}

.footer a {
  color: #7b7b7b;
}

.footer a:hover {
  color: #ffffff;
}

@media only screen and (min-width: 768px) {
  h2 {
    font-size: 160%;
  }

  h3 {
    font-size: 110%;
  }

  .data-header {
    padding: 20px;
  }

  .data-lower {
    padding: 0 20px 20px 20px;
  }

  .location-btn {
    font-size: 90%;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

